import { encodeOpts as paramsSerializer } from '@/utils/encode-opts';

const discountCodesApiFactory = (axios) => ({
  applyDiscountCode: async (storeId, code, data) =>
    (await axios.patch(`/stores/${storeId}/discount-codes/${encodeURIComponent(code)}/apply`, data)).data,
  rejectDiscountCode: async (storeId, codeId, params) =>
    (await axios.delete(`/stores/${storeId}/discount-codes/${codeId}`, { params, paramsSerializer })).data,
  getDiscountByOrder: async (storeId, orderId) =>
    (await axios.get(`/stores/${storeId}/discount-codes/by-order/${orderId}`)).data,
  getDiscountByCode: async (storeId, params) =>
    (await axios.get(`/stores/${storeId}/discount-codes`, { params, paramsSerializer })).data,
});

export { discountCodesApiFactory };
