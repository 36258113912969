import { changeQuery } from '../utils/url';
import { CONFIGURATION_TYPE } from '../utils/productPrices';
import { productDecorationsMixin } from './productDecorations';
import productPricesMoreMixin from './productPricesMore';
import { mapGetters } from 'vuex';
import productDiscountMixin from '@mtntop/utils/src/mixins/productDiscountMixin';

export const findMinQuantityPrice = (part, quantity, type) => {
  if (!type || (type && !part.pricing.find((e) => e.configurationType === type))) type = 'Decorated';
  let partPriceArray = [];
  if (part && part.pricing && part.pricing.length) {
    partPriceArray = _.orderBy(
      part.pricing.filter((el) => (el.configurationType || type) === type),
      ['minQuantity'],
      ['asc']
    );
    if (!partPriceArray.length) {
      type = 'Blank';
      partPriceArray = _.orderBy(
        part.pricing.filter((el) => (el.configurationType || type) === type),
        ['minQuantity'],
        ['asc']
      );
    }
    if (!partPriceArray.length) {
      partPriceArray = _.orderBy(part.pricing, ['minQuantity'], ['asc']);
    }
  }
  return partPriceArray;
};
export const findPriceByQuantity = (part, quantity, type, isSample) => {
  const partPriceArray = findMinQuantityPrice(part, quantity, type);
  if (!partPriceArray.length) return;
  quantity = parseInt(quantity);
  let priceIndex = -1;
  for (let i = 0; i < partPriceArray.length; i++) {
    if (quantity >= partPriceArray[i].minQuantity) {
      priceIndex = i;
    }
  }
  let partPrice;
  if (isSample) {
    partPrice = partPriceArray[partPriceArray.length - 1]; // cheapest
  } else {
    partPrice =
      partPriceArray[
        priceIndex === -1 ? (quantity < partPriceArray[0].minQuantity ? 0 : partPriceArray.length - 1) : priceIndex
      ];
  }
  if (!partPrice.price && partPrice.unitPrice) {
    partPrice.price = partPrice.unitPrice;
  }
  return partPrice;
};
export function unitPriceCalc(price) {
  try {
    const res = price.unitCost / (1 - (price.margin || 0) / 100);
    return isNaN(res) || res === Infinity || res === -Infinity || res.toFixed(2) === '0.00'
      ? price.unitPrice || 0
      : res;
  } catch (e) {
    return 0;
  }
}
export default {
  data() {
    const availableTypes =
      this.productData?.parts.map((e) => [...new Set(e.pricing.map((f) => f.configurationType).filter((e) => e))])[0] ||
      [];
    const configurationType = Array.isArray(this.$route.query.configurationType)
      ? this.$route.query.configurationType[0]
      : this.$route.query.configurationType || '';
    const type = Object.values(CONFIGURATION_TYPE).find((e) => e.toLowerCase() === configurationType.toLowerCase());
    let selectedConfigurationType =
      (type && Object.values(CONFIGURATION_TYPE).find((e) => e.toLowerCase().match(type.toLowerCase()))) ||
      (availableTypes.includes(CONFIGURATION_TYPE.Decorated) && CONFIGURATION_TYPE.Decorated) ||
      (availableTypes.includes(CONFIGURATION_TYPE.Blank) && CONFIGURATION_TYPE.Blank) ||
      (!availableTypes.length && CONFIGURATION_TYPE.Decorated) ||
      CONFIGURATION_TYPE.Sample;
    if (this.$route.path.toLowerCase().startsWith('/sample/')) {
      selectedConfigurationType = CONFIGURATION_TYPE.Sample;
    }
    return {
      selectedConfigurationType,
      timeout: null,
      oldInputQuantity: {
        orderData: null,
        quantity: null,
        partSizes: {},
      },
      partSizes: [],
      partsSizesByIndex: [],
      allPartsSizes: [],
    };
  },

  mixins: [productDiscountMixin, productDecorationsMixin, productPricesMoreMixin],
  watch: {
    partsSizesByIndex: {
      handler(val) {
        this.allPartsSizes = [].concat.apply([], val); // [[sizes],[sizes],[sizes]]
      },
      deep: true,
    },
  },
  destroyed() {
    if (this.timeout) clearTimeout(this.timeout);
  },
  methods: {
    setPartsSizesQuantity(quantity) {
      this.allPartsSizes.forEach((i) => {
        i.quantity = quantity;
      });
    },
    onSelectedConfigurationType(type) {
      if (this.$route.path.toLowerCase().startsWith('/sample/')) {
        return;
      }
      const oldVal = this.selectedConfigurationType;
      this.selectedConfigurationType = type;
      this.focusDecoration(type);
      this.checkOrderData();
      this.onResetQuantity(type, oldVal);
    },
    onResetQuantity(newVal, oldVal) {
      try {
        if (newVal === CONFIGURATION_TYPE.Sample) {
          if (this.orderData) {
            this.oldInputQuantity.orderData = this.orderData.quantity;
            this.orderData.quantity = 1;
          }
          this.oldInputQuantity.quantity = this.quantity;
          this.quantity = 1;
          if (this.allPartsSizes) {
            let hasPrice = false;
            for (let i = 0; i < this.allPartsSizes.length; i++) {
              if (typeof this.allPartsSizes[i].quantity !== 'undefined' && this.allPartsSizes[i].quantity) {
                this.oldInputQuantity.partSizes[this.allPartsSizes[i].code] = this.allPartsSizes[i].quantity;
                if (hasPrice) {
                  this.allPartsSizes[i].quantity = null;
                } else {
                  this.allPartsSizes[i].quantity = 1;
                  hasPrice = true;
                }
              }
            }
          }
        } else if (oldVal === CONFIGURATION_TYPE.Sample) {
          let quantity = this.oldInputQuantity.quantity || this.oldInputQuantity.orderData;
          let needToCalc = 0;
          if (!quantity) {
            quantity = 0;
            needToCalc = 1;
          }
          if (this.allPartsSizes && this.allPartsSizes.length) {
            for (let i = 0; i < this.allPartsSizes.length; i++) {
              const oldValQ = this.oldInputQuantity.partSizes[this.allPartsSizes[i].code];
              if (typeof oldValQ !== 'undefined') {
                if (oldValQ) {
                  this.allPartsSizes[i].quantity = oldValQ;
                } else {
                  this.allPartsSizes[i].quantity = findMinQuantityPrice(
                    this.productData.parts.find((e) => e._id === this.allPartsSizes[i].partId),
                    '',
                    newVal
                  )[0].minQuantity;
                }
                if (needToCalc) {
                  quantity += parseInt(this.allPartsSizes[i].quantity);
                }
              } else if (needToCalc) {
                this.allPartsSizes[i].quantity = findMinQuantityPrice(
                  this.productData.parts.find((e) => e._id === this.allPartsSizes[i].partId),
                  '',
                  newVal
                )[0].minQuantity;
                quantity += parseInt(this.allPartsSizes[i].quantity);
                break;
              }
            }
          } else {
            if (needToCalc) {
              quantity = (this.selectedPart && findMinQuantityPrice(this.selectedPart, '', newVal)[0].minQuantity) || 0;
            }
          }

          if (this.orderData) {
            this.orderData.quantity = quantity;
          }
          this.quantity = quantity;
        }
      } catch (e) {
        console.error(e);
      }
    },
    focusDecoration(selectedConfigurationType) {
      if (
        this.productData.parts.find((e) => e.pricing.find((p) => p.configurationType === selectedConfigurationType)) &&
        selectedConfigurationType &&
        !this.$route.fullPath.includes('cat/')
      ) {
        changeQuery(
          Object.assign(JSON.parse(JSON.stringify(this.$route.query)), {
            configurationType: selectedConfigurationType,
          }),
          1
        );
      }
    },
    minPartPriceInfo(part, quantity, skipConfigType) {
      if (!quantity && this.productData && this.productData.decorationProducts.length && this.orderData)
        quantity = this.orderData?.quantity;
      if (!quantity) quantity = parseFloat(this.quantity || 0);
      if (part && part.pricing && part.pricing.length) {
        const isSample = this.configurationType === CONFIGURATION_TYPE.Sample || !!this.$route.path.match('/sample/');
        return findPriceByQuantity(
          part,
          quantity,
          skipConfigType ? '' : this.tempSelectedConfigurationType || this.selectedConfigurationType,
          isSample
        );
      } else if (!this.productData) {
        return {
          ...part,
          margin: part.margin || 0,
          unitCost: part.unitCost || 0,
          unitPrice: part.unitPrice || 0,
          minQuantity: 0,
        };
      }
      return { unitPrice: 0, unitCost: 0, margin: 0, minQuantity: this.productData.minQuantity };
    },
    calcOrderQuantity(part, skipSizes) {
      if (part.ApparelSize && !skipSizes) {
        return (
          (this.allPartsSizes?.length &&
            this.allPartsSizes.map((el) => el.quantity).reduce((c, n) => parseFloat(c) + parseFloat(n), 0)) ||
          this.quantity
        );
      } else {
        return (this.orderData && parseFloat(this.orderData.quantity)) || this.quantity;
      }
    },
    calcTotalProductView() {
      return this.calcTotal(this.selectedPart, this.productData ? this.productData.parts : []);
    },
    calcBoxTotal(part, parts, quantity) {
      if (part.ApparelSize) {
        return this.calcTotalWithSizes(parts, false, quantity);
      }
      return this.calcTotal(part, parts, false, false, quantity);
    },
    calcTotal(part, productParts, skipSizes = false, noPartsInfo = false, partQuantity) {
      if (!part) return 0;
      // part = JSON.parse(JSON.stringify(part));
      if (part.ApparelSize && !skipSizes) {
        return this.calcTotalWithSizes(productParts, noPartsInfo);
      }
      const quantity = this.calcOrderQuantity(part, skipSizes);

      let {
        _id,
        perEach,
        margin,
        unitPrice,
        usedMargin,
        unitCost,
        productTotal,
        setUpChargePrice,
        configurationType,
        productTotalCost,
        productDiscount,
        partDiscount,
        originUnitPrice,
      } = this.calcOrderData({ part, quantity, partQuantity });

      let partCalWithOriginPrices;
      let orPrice =
        part && part.pricing && part.pricing.filter((el) => el.configurationType === this.selectedConfigurationType)[0];
      if (orPrice && orPrice.originPrice) {
        partCalWithOriginPrices = this.calcOrderData({
          part: {
            ...part,
            pricing: [
              {
                ...orPrice.originPrice,
                margin: usedMargin,
              },
            ],
          },
          quantity,
          usedCharge: false,
          isOwnDecor: true,
          partQuantity,
        });
      }
      const total = productTotal + (!noPartsInfo ? this.totalSubPartsPrice.totalPrice : 0);
      const resp = {
        partDiscount,
        originUnitPrice,
        perEach,
        productDiscount,
        configurationType,
        productTotal: total,
        productTotalCost,
        setUpChargePrice,
        unitCost,
        unitPrice,
        margin,
        total,
      };

      if (this.productData.noPricingStore && !part.ApparelSize) {
        part.quantity = this.quantity || (this.orderData && this.orderData.quantity);
      }

      if (part && !resp.parts) resp.parts = [{ ...part }];
      (resp.parts || []).forEach((part) => {
        if (partCalWithOriginPrices) {
          part.partCalWithOriginPrices = partCalWithOriginPrices;
        }
        ['unitPrice', 'unitCost', 'perEach', 'partDiscount', 'originUnitPrice', 'margin'].forEach((key) => {
          if (
            (resp[key] && typeof part[key] === 'undefined') ||
            (part._id === _id && typeof resp[key] !== 'undefined')
          ) {
            part[key] = resp[key];
          }
        });
      });
      if (partCalWithOriginPrices) {
        resp.calWithOriginPrices = partCalWithOriginPrices;
      }
      return resp;
    },
    calcTotalWithSizes(productParts, noPartsInfo, orderQuantity) {
      let partSizes = this.allPartsSizes;
      const quantity = orderQuantity || this.quantity || (this.orderData && this.orderData.quantity) || 1;
      if (!partSizes.length) {
        partSizes = [
          {
            quantity,
            partId: this.selectedPart._id,
          },
        ];
      }
      const res = this.calcTotalProduct(this.productData, partSizes, productParts, quantity);
      const subPartTotal = !noPartsInfo ? this.totalSubPartsPrice.totalPrice.roundCeil() : 0;
      if (!isNaN(subPartTotal)) res.productTotal += subPartTotal;
      res.total = res.productTotal; //+ parseFloat(res.setUpChargePrice);

      this.orderData.quantity = res.quantity; //sum of all sizes quantity
      return res;
    },
    calcTotalProduct(product, partsList, productParts, orderQuantity) {
      let lineProductDiscount = 0;
      let lineProductTotal = 0;
      let lineProductTotalCost = 0;
      let lineProductUnitCost = 0;
      let lineProductUnitPrice = 0;
      let lineProductTotalQuantity = 0;
      let linePartDiscount = 0;
      let linePerEach = 0;
      let lineMargin = 0;
      let usedCharge = 0;
      const setUpChargePrice = this.setUpChargePriceVal;
      const parts = [];
      for (let i = 0; i < partsList.length; i++) {
        const partInfo = partsList[i];
        const quantity = parseInt(partInfo.quantity);
        if (!quantity || isNaN(quantity)) {
          continue;
        }
        lineProductTotalQuantity += quantity;
        const part = productParts.find((el) => el._id === partInfo.partId || el._id === partInfo._id);
        if (!part) {
          console.log('calcTotalProduct:no part-');
          continue;
        }
        let partCalWithOriginPrices;
        let {
          perEach,
          margin,
          usedMargin,
          unitPrice,
          unitCost,
          configurationType,
          productTotal,
          productTotalCost,
          productDiscount,
          partDiscount,
          originUnitPrice,
        } = this.calcOrderData({
          part,
          quantity: orderQuantity,
          partQuantity: quantity,
          usedCharge,
          isOwnDecor: false,
          productData: product,
        });
        // console.log('perEach', perEach)
        let orPrice =
          part.pricing && part.pricing.filter((el) => el.configurationType === this.selectedConfigurationType);
        if (orPrice.length && orPrice.find((e) => e.originPrice)) {
          partCalWithOriginPrices = this.calcOrderData({
            part: {
              ...part,
              pricing: orPrice.map((e) => ({
                ...e.originPrice,
                unitPrice: null,
                margin: usedMargin,
              })),
            },
            quantity,
            usedCharge,
            isOwnDecor: true,
            productData: product,
          });
        }
        usedCharge = true;

        lineProductDiscount += productDiscount;
        lineProductTotal += parseFloat(productTotal);
        lineProductTotalCost += productTotalCost;
        if (!lineProductUnitCost) {
          lineProductUnitCost = unitCost;
          lineProductUnitPrice = unitPrice;
          linePartDiscount = partDiscount;
          linePerEach = perEach;
          lineMargin = margin;
        }
        parts.push({
          ...part,
          quantity,
          partDiscount,
          originUnitPrice,
          perEach,
          productDiscount,
          productTotal,
          productTotalCost,
          setUpChargePrice: i > 0 ? 0 : setUpChargePrice,
          unitCost,
          configurationType,
          unitPrice,
          margin,
        });
        if (partCalWithOriginPrices) {
          parts[parts.length - 1].partCalWithOriginPrices = partCalWithOriginPrices;
        }
      }
      const resp = {
        parts,
        setUpChargePrice,
        quantity: lineProductTotalQuantity,
        productTotal: lineProductTotal,
        productDiscount: lineProductDiscount,
        productTotalCost: lineProductTotalCost,
        partDiscount: linePartDiscount,
        perEach: linePerEach,
        unitCost: lineProductUnitCost,
        unitPrice: lineProductUnitPrice,
        margin: lineMargin,
      };
      if (parts.length && parts[0].partCalWithOriginPrices) {
        resp.calWithOriginPrices = {
          productDiscount: 0,
          productTotalCost: 0,
          productTotal: 0,
        };

        for (let i = 0; i < parts.length; i++) {
          if (parts[i].partCalWithOriginPrices) {
            resp.calWithOriginPrices.productTotal += parts[i].partCalWithOriginPrices.productTotal;
            resp.calWithOriginPrices.productTotalCost += parts[i].partCalWithOriginPrices.productTotalCost;
            resp.calWithOriginPrices.productDiscount += parts[i].partCalWithOriginPrices.productDiscount;
            if (!resp.calWithOriginPrices.unitCost) {
              resp.calWithOriginPrices.margin = parts[i].partCalWithOriginPrices.margin;
              resp.calWithOriginPrices.partDiscount = parts[i].partCalWithOriginPrices.partDiscount;
              resp.calWithOriginPrices.unitPrice = parts[i].partCalWithOriginPrices.unitPrice;
              resp.calWithOriginPrices.unitCost = parts[i].partCalWithOriginPrices.unitCost;
              resp.calWithOriginPrices.perEach = parts[i].partCalWithOriginPrices.perEach;
            }
          }
        }
      }
      return resp;
    },
    calcTotalDecorated(product) {
      return {
        ...product,
        ...this.calcTotalProduct(
          product,
          product.parts.map((el) => ({
            ...JSON.parse(JSON.stringify(el)),
            partId: el._id,
          })),
          product.parts
        ),
      };
    },
    calcOrderData({ part, quantity, usedCharge, isOwnDecor, productData, partQuantity }) {
      // const { currencyRate = 1 } = this.storeInformation;
      const currencyRate = 1;
      quantity = parseInt(quantity);
      const withoutCharge = !this.$route.path.match('/sample/') && !this.configurationType;
      let setUpChargePrice = this.setUpChargePriceVal;
      if (isOwnDecor) {
        if (this.selectedDecoration && this.selectedDecoration.productId === (productData || this.productData)._id) {
        } else {
          setUpChargePrice = 0;
        }
      }
      if (this.tempSelectedConfigurationType && this.tempSelectedConfigurationType !== CONFIGURATION_TYPE.Decorated) {
        setUpChargePrice = 0;
      }
      const minInfo = this.minPartPriceInfo(part, quantity);
      // console.log('minInfo', minInfo)
      if (!minInfo) {
        return {};
      }
      let unitPrice = parseFloat(minInfo.unitPrice ?? unitPriceCalc(minInfo)) * currencyRate;
      const originUnitPrice = unitPrice;
      const unitCost = parseFloat(minInfo.unitCost) * currencyRate;
      const storeDiscount = this.storeProductDiscountMain(productData || this.productData) * currencyRate;
      const partDiscount = 0; // storeDiscount ? parseFloat(storeDiscount * unitPrice) : 0;
      if (storeDiscount) {
        unitPrice = (unitPrice - unitPrice * storeDiscount).roundCeil(2);
      }
      const perEachQuantity = partQuantity || quantity;
      const productDiscount = partDiscount * perEachQuantity;
      let productTotal = parseFloat(
        perEachQuantity * unitPrice + (withoutCharge && !usedCharge ? setUpChargePrice : 0)
      );
      if (isNaN(productTotal)) productTotal = 0;

      let productTotalCost = parseFloat(parseFloat(perEachQuantity * unitCost)).roundCeil();
      const perEach = productTotal / perEachQuantity;
      const margin = unitPrice !== 0 ? parseFloat(((unitPrice - unitCost) / unitPrice) * 100) : 0;
      // console.log(productTotal)
      return {
        _id: part._id,
        perEach,
        usedMargin: minInfo.margin,
        originUnitPrice,
        margin,
        unitPrice,
        unitCost,
        configurationType: minInfo.configurationType,
        productTotal,
        productTotalCost,
        setUpChargePrice,
        productDiscount,
        partDiscount,
      };
    },
    setUpChargePriceAdditionalL(selectedDecoration) {
      const dec =
        selectedDecoration?.supplierCode && selectedDecoration?.selectedDecoration
          ? selectedDecoration?.selectedDecoration
          : selectedDecoration;
      const isDecoration =
        this.showDecoration && dec?.charges?.length && (this.productView?.decoration?.show || !this.productView);

      return parseFloat(
        isDecoration ? this.chargeQuantityPrice(dec.charges[1]) * (this.orderData?.quantity || 0) || 0 : 0
      );
    },
    chargeQuantityPrice(charge) {
      if (!charge) return 0;
      const price = findPriceByQuantity(
        { pricing: charge.pricing.map((e) => ({ ...e, minQuantity: e.x_min_qty, configurationType: 'Decorated' })) },
        this.orderData?.quantity
      );
      charge.price = (price && (price.price || price.repeat_price)) || 0;
      return charge.price;
    },
  },
  computed: {
    ...mapGetters({
      storeInformation: 'storeInformation',
    }),
    showDecoration() {
      return this.selectedConfigurationType === 'Decorated';
    },
    showDecorationSection() {
      return (
        this.showDecoration &&
        this.viewDecoration &&
        // (this.showDecoration || !this.productData) &&
        ((this.configureProduct.decorationArtwork && this.configureProduct.decorationArtwork.show) ||
        (this.configureProduct.decorationColors && this.configureProduct.decorationColors.show) ||
        (this.configureProduct.decorationComments && this.configureProduct.decorationComments.show) ||
        // (this.defaultDecoration && this.productData.allDecorationsLoaded) ||
        (this.configureProduct.decorationTypeset && this.configureProduct.decorationTypeset.show) || // (this.defaultDecoration && this.productData.allDecorationsLoaded) ||
          (this.configureProduct.decorationMethods && this.configureProduct.decorationMethods.show) ||
          (this.configureProduct.decoration && this.configureProduct.decoration.show))
      );
    },
    selectedQuantity() {
      const pricing = this.productPrices;
      let index = -1;
      const quantity = parseInt(this.quantity, 10);
      for (let i = 0; i < pricing.length; i++) {
        if (quantity >= pricing[i].minQuantity) {
          index = i;
        }
      }
      return index;
    },
    productPrices() {
      const part = this.productData.parts.find((part) => this.selectedPart && part._id === this.selectedPart._id);
      if (!part || !part.pricing) return [];
      const { pricing } = part;
      let res = _.orderBy(
        pricing.filter((el) => (el.configurationType || 'Decorated') === this.selectedConfigurationType),
        ['minQuantity'],
        ['asc']
      );
      return _.uniqBy(res, (el) => el.minQuantity).filter(
        (i) => (i.unitPrice && i.unitPrice > 0) || (i.price && i.price > 0)
      );
    },
    setUpChargePriceVal() {
      if (this.showDecoration && this.selectedDecoration && (this.productView?.decoration?.show || !this.productView)) {
        let setupPrice = this.setUpChargePrice;

        if (this.selectedDecoration) {
          setupPrice = this.selectedDecoration.setUpChargePrice;

          if (this.selectedDecoration.selectedCharge) {
            const selectedPart = this.selectedDecorationProductPart;
            if (selectedPart) {
              const keysV = ['xMinQty', 'yMinQty', 'xUom', 'yUom'];
              const price = this.selectedDecoration.selectedCharge.pricing.find(
                (price) =>
                  keysV.filter((e, index) => selectedPart.charge_prices?.find((pr) => pr[e] === price[keysV[index]]))
                    .length === keysV.length
              );
              if (price) setupPrice = price.price;
            }
          }
        }
        if (!setupPrice) setupPrice = this.setUpChargePrice;
        return parseFloat(setupPrice) + this.setUpChargePriceAdditional;
      }
      return 0;
    },
    setUpChargePriceAdditional() {
      return this.setUpChargePriceAdditionalL(this.selectedDecoration);
    },
    orderQuantity() {
      if (!this.selectedPart || !this.orderData) return 0;
      if (this.selectedPart.ApparelSize) {
        return this.allPartsSizes.map((el) => el.quantity).reduce((c, n) => parseFloat(c) + parseFloat(n), 0);
      } else {
        return parseFloat(this.orderData.quantity);
      }
    },
    totalSubPartsPrice() {
      let totalPrice = 0,
        list = this.selectedSubParts || [],
        parts = [];
      for (let i = 0; i < list.length; i++) {
        const part = list[i];
        const minData = this.minPartPriceInfo(part);
        const unitPrice = parseFloat(minData.price); //Getting unitPrice by 1 item
        const unitCost = parseFloat(minData.price); //Getting unitCost by 1 item
        let quantity = this.selectedPartsQuantities.length ? this.selectedPartsQuantities[i] : this.orderQuantity;
        const perEachRelation = Math.ceil(quantity * (part.ratio || 1)); //Getting price by 1 item and use ratio if exist

        let productPartTotal = parseFloat(unitPrice * perEachRelation);
        let productPartTotalCost = parseFloat(unitCost * perEachRelation).roundCeil();
        if (part.sizes) {
          Object.keys(part.sizes).forEach((key) => {
            if (part.sizes[key]) {
              const quantity = parseInt(part.sizes[key]);
              productPartTotal += unitPrice * quantity;
              productPartTotalCost += unitCost * quantity;
            }
          });
        }
        if (isNaN(productPartTotal)) productPartTotal = 0;
        const data = {
          ...part,
          unitPrice,
          uom: minData.uom,
          unitCost,
          margin: (((productPartTotal - productPartTotalCost) / productPartTotal) * 100).roundCeil(),
          totalPrice: productPartTotal,
        };
        ['margin', 'minPrice'].forEach((key) => {
          if (isNaN(data[key])) data[key] = 0;
        });

        if (data.partGroup !== '1') {
          data.quantity = perEachRelation;
        }
        parts.push(data);
        if (!isNaN(data.totalPrice)) totalPrice += data.totalPrice;
      }
      return { totalPrice, parts };
    },
  },
};
