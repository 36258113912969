import { checkSupplierPriceConfigurationType, feedSamplePrices } from '../utils/productPrices';
export const EVENTS = {
  START_CHECKING_PRICES: 'START_CHECKING_PRICES',
  FINISH_CHECKING_PRICES: 'FINISH_CHECKING_PRICES',
};
export const PRICE_REFRESH = {
  ON_CHANGE_DECORATION_PRODUCT: 'ON_CHANGE_DECORATION_PRODUCT',
  ON_CHANGE_MAPPED_DECORATION: 'ON_CHANGE_MAPPED_DECORATION',
  ON_CHANGE_LOCATION_DECORATION_PRICES: 'ON_CHANGE_LOCATION_DECORATION_PRICES',
};
const sleep = (t) => new Promise((re) => setTimeout(re, t));
export const productDecorationsMixin = {
  data() {
    return {
      loadingCheckPrices: false,
      priceUpdatedAt: 1,
    };
  },
  methods: {
    async onChangeProductDecoration({ _id, parts, selectedPart }, selectedParts, type, next) {
      try {
        if (!type) {
          type = PRICE_REFRESH.ON_CHANGE_DECORATION_PRODUCT;
        }
        if (type === PRICE_REFRESH.ON_CHANGE_DECORATION_PRODUCT && (!parts || !parts.length)) {
          return;
        }
        if (this.loadingCheckPrices) {
          return;
        }
        this.loadingCheckPrices = true;
        await sleep(300);
        if (process.browser) document.body.dispatchEvent(new Event(EVENTS.START_CHECKING_PRICES));
        let updatedPartsAll;
        switch (type) {
          case PRICE_REFRESH.ON_CHANGE_DECORATION_PRODUCT: {
            updatedPartsAll = await this.$api.products.checkPrices(this.productData._id, {
              decorationProducts: [{ _id, parts, selectedPart }],
            }); //TODO: maybe for secure need to provide only id
            break;
          }
          case PRICE_REFRESH.ON_CHANGE_MAPPED_DECORATION: {
            updatedPartsAll = await this.$api.products.checkMappedDecorationPrices(
              this.productData._id,
              this.productData
            );
            break;
          }
          case PRICE_REFRESH.ON_CHANGE_LOCATION_DECORATION_PRICES: {
            updatedPartsAll = await this.$api.products.checkMappedDecorationPrices(this.productData._id, {
              ...this.productData,
              decorations: [],
              parts: this.productData.parts.map((e) => e._id),
              locations: this.productData.locations.map((e) => e._id),
              onlyLocationDecorationPrices: 1,
            });
            const list = [this.productData.parts, selectedParts];
            while (list.length) {
              const parts = list.shift();
              if (!parts) continue;
              const updatedParts = [...updatedPartsAll];
              while (updatedParts.length) {
                const part = updatedParts.shift();
                for (let i = 0; i < parts.length; i++) {
                  const existPart = parts[i];
                  if (existPart._id === part._id) {
                    Object.assign(existPart.locationDecorationPricing, part.locationDecorationPricing);
                    existPart.updatedAt = Date.now();
                  }
                }
              }
            }

            if (next) next();
            this.priceUpdatedAt = Date.now();
            throw 'price refreshed';
          }
        }

        const list = [this.productData.parts, selectedParts];
        while (list.length) {
          const parts = list.shift();
          if (!parts) continue;
          const updatedParts = [...updatedPartsAll];
          while (updatedParts.length) {
            const part = updatedParts.shift();
            for (let i = 0; i < parts.length; i++) {
              const existPart = parts[i];
              if (existPart._id === part._id) {
                existPart.pricing = part.pricing;
                existPart.updatedAt = Date.now();
              }
            }
          }
          const _parts = parts;
          for (let i = 0; i < _parts.length; i++) {
            const existPart = _parts[i];
            if (!existPart.sizes) existPart.sizes = [];
            existPart.sizes.forEach((e) => {
              for (let i = 0; i < _parts.length; i++) {
                if (_parts[i]._id === e.partId) {
                  e.prices = _parts[i].pricing;
                }
              }
            });
          }
          if (type === PRICE_REFRESH.ON_CHANGE_DECORATION_PRODUCT) {
            feedSamplePrices(parts, this.productData);
          }
        }
        checkSupplierPriceConfigurationType(this.productData);

        this.priceUpdatedAt = Date.now();
      } catch (e) {
        console.log(e);
      } finally {
        this.loadingCheckPrices = false;
        if (process.browser) document.body.dispatchEvent(new Event(EVENTS.FINISH_CHECKING_PRICES));
      }
    },
  },
};
