/**
 * round depend from 3 digit after coma, 0.011 -> result is 0.02. Same as Math.ceil just for 3th decimal
 * (0.01132).toFixed(2) -> 0.01
 * (0.01132).roundCeil(2) -> 0.02
 * Note any change here require updates on feeds, Math working differently on nodejs and browser
 * */
const roundCeil = function(range = 2) {
  try {
    const str = this.toString();
    if (isNaN(parseFloat(str))) return this;
    const v = parseFloat(this.toFixed(Math.min(str.length, 10))).toString();
    const dec = v.split('.');
    let res = parseFloat(v);
    if (dec[1]) {
      res = parseFloat(`${dec[0]}.${dec[1].substr(0, range)}`);
      if (parseInt(dec[1].substr(range, 1)) > 0) {
        return parseFloat((res + 1 / Math.pow(10, range)).toFixed(range));
      }
    }
    return res;
  } catch (e) {
    return this;
  }
};
const toCapitalize = function() {
  return this.toLowerCase().replace(/^.|\s\S/g, function(a) {
    return a.toUpperCase();
  });
};

const toCamelCase = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

const enumToNormal = function() {
  return this.replace(/_/g, ' ').toCapitalize();
};

Number.prototype.roundCeil = roundCeil;
String.prototype.roundCeil = roundCeil;
String.prototype.toCapitalize = toCapitalize;
String.prototype.enumToNormal = enumToNormal;
String.prototype.toCamelCase = toCamelCase;
setTimeout(() => {
  Number.prototype.roundCeil = roundCeil;
  String.prototype.roundCeil = roundCeil;
  String.prototype.toCapitalize = toCapitalize;
  String.prototype.enumToNormal = enumToNormal;
  String.prototype.toCamelCase = toCamelCase;
  //TODO; bit dev not working, need to rewrite
}, 1000);
String.prototype.toCapitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
};
try {
  if (!{}.asJson)
    Object.defineProperty(Object.prototype, 'asJson', {
      value: function() {
        return JSON.parse(JSON.stringify(this));
      },
    });
} catch (e) {}
