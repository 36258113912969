import Vue from 'vue';

import VueMask from 'v-mask';
import VeeValidate, { Validator } from 'vee-validate';

const dict = {
  custom: {
    Quantity: {
      min_value: (field, val) => `Please enter a quantity of ${val} or greater`,
    },
  },
};

Validator.extend('partSizes', {
  getMessage(field, params, data) {
    return `Error`;
  },
  validate: async (value, [testProp]) => {
    return testProp === 1;
  },
});
Validator.extend('increment', {
  getMessage(field, params, data) {
    return `Must be ordered in increments of ${params}`;
  },
  validate: (value, [testProp]) => {
    return parseInt(value) % parseFloat(testProp) === 0;
  },
});

Validator.extend(
  'enum',
  {
    validate: (value, params) => {
      return params.values.includes(value);
    },
    getMessage: (field, params) => {
      return `can be only: ${params[0].join(', ')}`;
    },
  },
  {
    paramNames: ['values'],
  }
);
Validator.extend('maxVal', {
  //standart max work only with digits
  getMessage(field, params, data) {
    return `Field should be less than ${params}`;
  },
  validate: (value, [testProp]) => {
    return parseFloat(value) <= parseFloat(testProp);
  },
});
Validator.extend('minVal', {
  getMessage(field, params, data) {
    return `Field should be more than ${params}`;
  },
  validate: (value, [testProp]) => {
    return parseFloat(value) >= parseFloat(testProp);
  },
});
Validator.extend('increment', {
  getMessage(field, params, data) {
    return `Field should be divisible by ${params}`;
  },
  validate: (value, [testProp]) => {
    return parseInt(value) % parseFloat(testProp) === 0;
  },
});

Validator.localize('en', dict);
Vue.use(VueMask);
Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: 'veeFields',
});
