import { storesApiFactory } from '@/api/stores';
import { usersApiFactory } from '@/api/users';
import { suppliersApiFactory } from '@/api/suppliers';
import { quickbooksApiFactory } from '@/api/quickbooks';
import { productsApiFactory } from '@/api/products';
import { ordersApiFactory } from '@/api/orders';
import { orderProgressStepsApiFactory } from '@/api/order-progress-steps';
import { newsletterApiFactory } from '@/api/newsletter';
import { instagramApiFactory } from '@/api/instagram';
import { followupsApiFactory } from '@/api/followUps';
import { facetsApiFactory } from '@/api/facets';
import { customersApiFactory } from '@/api/customers';
import { creditAllowancesApiFactory } from '@/api/credits-allowances';
import { companiesApiFactory } from '@/api/companies';
import { categoriesApiFactory } from '@/api/categories';
import { authApiFactory } from '@/api/auth';
import { discountCodesApiFactory } from '@/api/discount-codes';
import { walkthroughsApiFactory } from '@/api/walkthroughs';
import { distributorsApiFactory } from '@/api/distributors';
import { uploadsApiFactory } from '@/api/uploads';

const api = {
  authApiFactory,
  categoriesApiFactory,
  companiesApiFactory,
  customersApiFactory,
  creditAllowancesApiFactory,
  suppliersApiFactory,
  storesApiFactory,
  newsletterApiFactory,
  productsApiFactory,
  ordersApiFactory,
  orderProgressStepsApiFactory,
  followupsApiFactory,
  facetsApiFactory,
  instagramApiFactory,
  usersApiFactory,
  quickbooksApiFactory,
  discountCodesApiFactory,
  walkthroughsApiFactory,
  distributorsApiFactory,
  uploadsApiFactory,
};
export { api as default };
