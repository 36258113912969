const cookieparser = process.server ? require('cookieparser') : undefined;
const Cookie = process.client ? require('js-cookie') : undefined;
const urlParse = process.server ? require('url') : undefined;

export const DEFAULT_ADDRESS = { contactName: '', companyName: '', country: 'US', state: '', city: '', zip: '' };

export const state = () => ({
  store: null,
  API_URL: null,
  IS_BOT_SERVER: null,
  IS_BOT_SERVER_REQUEST: null,
  IMAGE_API_URL: null,
  caching: null,
});

export const getters = {
  storeInformation: (state) => state.store,
  storePaymentProvider: (state) => state.store?.paymentProvider || '',
  storeCurrencySign: ({ store }) => store?.currency?.sign || '$',
  storeId: ({ store }) => store?._id,
  storeSupportCountriesCodes({ store }) {
    const storeCountries = store.countries || [];
    return storeCountries.length === 0 ? ['us'] : storeCountries.map(c => c.code.toLowerCase());
  },
  API_URL: (state) => state.API_URL,
  IS_BOT_SERVER: (state) => !!state.IS_BOT_SERVER,
  IS_BOT_SERVER_REQUEST: (state) => state.IS_BOT_SERVER_REQUEST,
  caching: (state) => state.caching,
  isDatePassed: (state) => {
    const currentDate = new Date();
    const expiryDate = new Date(state.store?.dontAllowOrdersAfterDate);

    return currentDate > expiryDate;
  },
  DEFAULT_COUNTRY_CODE: ({ store }) => {
    return (store.countries || [])[0]?.code || DEFAULT_ADDRESS.country;
  },
  DEFAULT_ADDRESS: ({ store }) => {
    return {
      ...DEFAULT_ADDRESS,
      country: (store.countries || [])[0]?.code || DEFAULT_ADDRESS.country,
    };
  },
};

export const actions = {
  setCaching({ commit }) {
    commit('SET_CACHING');
  },
  async nuxtClientInit({ dispatch, state, commit }) {
    let { referrer } = window.document;
    const referrerInCookie = Cookie.getJSON('referrer');
    if (!referrerInCookie && referrer && !referrer.includes(window.location.host)) {
      const queryIndex = referrer.indexOf('?');
      if (queryIndex > -1) referrer = referrer.slice(0, queryIndex);
      Cookie.set('referrer', referrer);
    }
    ['$http', '$axios'].forEach((key) => {
      if (this[key]) {
        this[key].defaults.headers.common['user-agent-1'] = process.env.NEWRELIC_APP_NAME || 'Mpower-store';
      }
    });
    if ((window.location?.href || '').match(/noCache/i) || state.caching > Date.now()) {
      this.$axios.defaults.headers.common['nocachepage'] = 1;
      commit('SET_CACHING');
    }

    if (Cookie.get('auth') && !this.getters['auth/isAuthenticated']) {
      dispatch('auth/auth_logout');
    }
    setTimeout(async () => {
      await dispatch('favorites/getFavorites');
      await dispatch('creditsAllowances/getCreditsAllowances');
      await dispatch('auth/checkSocket');
    });
  },

  async nuxtServerInit({ commit, dispatch, state }, ctx) {
    const { req, redirect, route } = ctx;
    // ctx.res.on('finish', (...e) => {
    //   console.log('finish-', e, ctx.res.json);
    // });

    ['$http', '$axios'].forEach((key) => {
      if (this[key]) {
        this[key].defaults.headers.common['user-agent-1'] = process.env.NEWRELIC_APP_NAME || 'Mpower-store';
      }
    });

    const isBotRequest = Object.keys(req.headers).find((key) =>
      req.headers[key]
        .toString()
        .toLowerCase()
        .match(/bot|lighthouse/gi)
    );

    const isBotServer = /apibot/.test(process.env.API_URL || '');
    commit('SET_API_URL', process.env.API_URL);
    commit('SET_IMAGE_API_URL', process.env.IMAGE_API_URL);
    commit('SET_IS_BOT_SERVER', isBotServer);
    if (isBotRequest) commit('SET_IS_BOT_SERVER_REQUEST', Date.now());

    var url_parts = urlParse.parse(req.url, true);
    let storeUrl =
      (process.env.STORE_URL === 'e2e' && 'e2e-test.mpowerpromo.ci') ||
      (process.env.STORE_URL === 'all' && (url_parts.query.storeUrl || process.env.STORE_URL_1)) ||
      process.env.STORE_URL ||
      req.headers.host;
    if (storeUrl) process.env.STORE_URL_1 = storeUrl;
    if (!storeUrl) {
      return ctx.error({ statusCode: 404, message: 'Store not found' });
    }
    const hasId = storeUrl.split('.').find((e) => e.toString().match(/^[0-9a-fA-F]{24}$/));
    // console.log('X-Real-Host', req.headers['X-Real-Host'], hasId, req.headers.host)
    if (hasId) storeUrl = req.headers['X-Real-Host'] || hasId;
    const referer = req.url;

    // something calling api from another host, probably aws issues. It's not causing error for stores
    if (req.url !== '/health') {
      let parsed, details;
      try {
        if (/[A-Z]+/.test(route.path || '') && process.env.NODE_ENV !== 'local') {
          const path = route.path; // encodeURI(route.path);
          return redirect(301, req.url.replace(path, path.toLowerCase()));
        }
        if (req.headers.cookie) {
          try {
            parsed = cookieparser.parse(req.headers.cookie);
            if (parsed.auth) this.$axios.defaults.headers.common['Authorization'] = parsed.auth;
            if (parsed.source) this.$axios.defaults.headers.common['mpower-source'] = parsed.source;
            if (parsed.sourceId) this.$axios.defaults.headers.common['mpower-source-id'] = parsed.sourceId;
          } catch (e) {
            if (e.toString().includes('RangeError: str is too large')) {
              req.headers.cookie = req.headers.cookie.replace(/search-history-1=\[[^\]]+\]/, 'search-history-1=[]');
            }
          }
        }

        const noCache =
          (referer || '').match(/noCache/i) ||
          (req?.headers?.referer || '').match(/noCache/i) ||
          state.caching > Date.now();

        const isEmptyApparelSizes = isBotServer || isBotRequest;
        try {
          // throw 'error';
          details = await ctx.$info.stores.getStoreInformation(storeUrl, { referer, noCache, isEmptyApparelSizes });
        } catch (e) {
          // console.log('Store temporarily not available...');
          // console.log(e);
          // console.log('process.env.API_TOKEN: ', process.env.API_TOKEN);
          // console.log('process.env.API_URL: ', process.env.API_URL);
          // console.log('storeUrl: ', storeUrl);
          return ctx.error({ statusCode: 503, message: `Store temporarily not available. ${storeUrl}` });
        }

        if (!(parsed && parsed.auth) || isBotServer || isBotRequest) {
          if (noCache) {
            this.$axios.defaults.headers.common['nocachepage'] = 1;
            commit('SET_CACHING');
          }
          if (details) {
            delete details.shipping;
            const storeInformation = details;
            if (isBotServer || isBotRequest) {
              storeInformation.apparelSizes = [];
            }
          }
        }

        if (!details?.storeUrl && !details.needRedirectToDomain && process.env.NODE_ENV !== 'local') {
          return redirect(301, `/not-found`);
        }
        const isNotActiveDistibutor =
          details?.distributor?.isActive === false || details.supplierId?.isActive === false;
        if (!details || isNotActiveDistibutor) {
          ctx.error({ statusCode: 404, message: `Store not found. ${storeUrl}` });
        } else if (details.needRedirectToDomain && process.env.NODE_ENV !== 'local') {
          redirect(301, `${details.needRedirectToDomain}${req.originalUrl}`);
        } else {
          const { user, ...store } = details;
          if (!store.storeUrl) return ctx.error({ statusCode: 404, message: `Store not found. ${storeUrl}` });
          commit('SET_STORE', store);
          if (parsed && parsed.auth) {
            commit('auth/AUTH_SUCCESS', { token: parsed.auth, customer: user });
          }
          if (parsed && parsed.sourceId) {
            commit('auth/SET_SOURCE', { sourceId: parsed.sourceId, source: parsed.source });
          }
        }
      } catch (e) {
        console.log('details failed2...');
        console.log(e);
      }
    }
  },
};

export const mutations = {
  SET_IMAGE_API_URL(state, data) {
    state.IMAGE_API_URL = data;
  },
  SET_CACHING(state, caching) {
    state.caching = caching || Date.now() + 1000 * 10;
  },
  SET_API_URL(state, data) {
    state.API_URL = data;
  },
  SET_IS_BOT_SERVER(state, IS_BOT_SERVER) {
    state.IS_BOT_SERVER = IS_BOT_SERVER;
  },
  SET_IS_BOT_SERVER_REQUEST(state, value) {
    state.IS_BOT_SERVER_REQUEST = value;
  },

  SET_STORE(state, store) {
    state.store = store;
    // if (process.env.NODE_ENV === 'local') {
    //   state.store = {
    //     ...state.store,
    //   };
    // }
  },
};
