import productDiscountMixin from '@mtntop/utils/src/mixins/productDiscountMixin';
export default {
  mixins: [productDiscountMixin],
  data() {
    return {
      item_list_name: '',
      listId: '',
    };
  },
  mounted() {
    try {
      let listId = 'products_home';
      if (location.pathname.startsWith('/cat')) {
        listId = `products_categories`;
      } else if (location.pathname.startsWith('/facets')) {
        listId = 'products_facets';
      } else if (location.pathname.startsWith('/supplier')) {
        listId = 'products_suppliers';
      } else if (location.pathname.startsWith('/theme')) {
        listId = 'products_theme';
      } else if (location.pathname.startsWith('/products')) {
        listId = 'products_search';
      }
      try {
        const additional = location.pathname
          .split('/')
          .splice(2)
          .filter((e) => e)
          .join('_');
        if (additional) listId += `_${additional}`;
      } catch (e) {}

      const item_list_name = listId
        .split('_')
        .map((e) => e.toCapitalize())
        .join(' ');
      Object.assign(this, {
        item_list_name,
        listId,
      });
    } catch (e) {}
  },
  methods: {
    productItemDataLayer(item, index = 0) {
      const discount = (item.priceMin * this.storeProductDiscountMain(item)).roundCeil();
      const brandGroup = (item.facetGroups || []).find((e) => e.slug === 'brand');
      const brand =
        item.facets.find((e) => e.facetGroup?.slug === 'brand' || e.facetGroup === brandGroup?._id)?.name ||
        item.supplierId?.name;
      return {
        item_id: item.productId,
        item_name: item.productName,
        affiliation: this.$store.getters.storeInformation.storeName,
        discount: isNaN(discount) ? 0 : discount,
        // location_id: 'ChIJIQBpAG2ahYAR_6128GcTUEo',
        // coupon: 'SUMMER_FUN',
        currency: this.$store.getters.storeInformation?.currency?.code || 'USD',
        index,
        ...(brand && {
          item_brand: brand,
        }),

        ...(Array.isArray(item.categoriesUsed) &&
          item.categoriesUsed.length && {
            item_category: item.categoriesUsed[0],
            ...item.categoriesUsed
              .splice(1)
              .map((e, index) => ({ [`item_category${index + 2}`]: e }))
              .reduce((a, b) => ({ ...a, ...b }), {}),
          }),

        item_list_id: this.listId,
        item_list_name: this.item_list_name,
        ...(item.item_variant && { item_variant: item.item_variant }),

        price: item.priceMin.roundCeil(),
        quantity: item.minQuantity,
      };
    },
  },
};
